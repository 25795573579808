// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";

import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";

import { faMinus as fasFaMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faAngleDown as fasFaAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faPlus as fasFaPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faFilePdf as fasFaFilePdf } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faNewspaper as fasFaNewspaper } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faCalendarAlt as fasFaCalendarAlt } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";

/**
 * Add regular icons
 */
library.add(farFaBars);

/**
 * Add solid icons
 */
library.add(fasFaTimes);


/**
 * Add solid icons
 */
library.add(fasFaMinus, fasFaPlus, fasFaFilePdf, fasFaNewspaper, fasFaCalendarAlt, fasFaAngleDown);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
